import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  AppBar,
  Toolbar,
  Box,
} from "@mui/material";
import { Heart, Droplets, Brain, Music } from "lucide-react";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import EmailIcon from "@mui/icons-material/Email";
import logo from "./logo.png";

// Define your theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#66558E",
      light: "#E9DDFF",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#2E628C",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#FEF7FF",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#1D1B20",
      secondary: "#49454E",
    },
  },
  typography: {
    fontFamily: "Nunito, sans-serif",
    h1: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 500,
    },
    h2: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 500,
    },
    h3: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 500,
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const IconSvg = ({ icon: Icon }) => {
  return <Icon size={48} style={{ color: theme.palette.primary.main }} />;
};

const features = [
  {
    title: "AI analysis on your Ultrasound reports",
    icon: Heart,
    description:
      "AI analysis on your ultrasound reports will help you to find out the most probable pregnancy outcomes",
  },
  {
    title: "Daily water intake tracker",
    icon: Droplets,
    description:
      "Get reminders on how much water you should be drinking and set daily water intake goals",
  },
  {
    title: "Stress Management",
    icon: Brain,
    description: "Stress management tools to help you manage stress levels",
  },
  {
    title: "Calm and peaceful music for good night sleep",
    icon: Music,
    description: "Manage your sleep by listening to calm and peaceful music",
  },
];

const FeatureCard = ({ title, icon, description }) => (
  <Card
    elevation={2}
    sx={{ height: "100%", display: "flex", flexDirection: "column" }}
  >
    <CardContent
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <IconSvg icon={icon} />
      <Typography variant="h6" component="h3" sx={{ mt: 2, mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </CardContent>
  </Card>
);

const LandingPage = () => {
  const handleJoinWaitlist = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScjpoYmQvDC9mdZgi_obFkGD7wd_rqHu0A3YXDV8Bs1wEt-xQ/viewform?usp=sf_link",
      "_blank"
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ color: "primary.main", fontWeight: "bold" }}
            >
              Goodnewzz
            </Typography>
            <img
              src={logo}
              alt="Goodnewzz Logo"
              style={{ height: "32px", marginLeft: "8px" }}
            />
          </Box>
          <Button color="inherit" href="#features">
            Features
          </Button>
          <Button color="inherit" href="#waitlist">
            Join Waitlist
          </Button>
          <Button color="inherit" href="#contact">
            Contact
          </Button>
        </Toolbar>
      </AppBar>

      <main>
        <Container maxWidth="md" sx={{ mt: 8, mb: 8, textAlign: "center" }}>
          <Typography variant="h2" component="h1" gutterBottom>
            Your Ultimate Pregnancy Companion
          </Typography>
          <Typography
            variant="h5"
            component="p"
            color="text.secondary"
            paragraph
          >
            Empowering expectant mothers with AI-powered insights and
            personalized care
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<EmailIcon />}
            onClick={handleJoinWaitlist}
            sx={{ mt: 2 }}
          >
            Join the Waitlist
          </Button>
        </Container>

        <Container id="features" maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
          <Typography variant="h3" component="h2" align="center" gutterBottom>
            Features
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item key={index} xs={12} sm={6} md={3}>
                <FeatureCard {...feature} />
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container
          maxWidth="lg"
          sx={{
            mt: 8,
            mb: 8,
            bgcolor: "primary.light",
            py: 8,
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <Typography variant="h3" component="h2" align="center" gutterBottom>
            Curated Soundtracks for Pregnancy
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="text.secondary"
            paragraph
          >
            Discover our specially designed audio tracks to support your
            pregnancy journey
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Music
                    size={48}
                    style={{
                      color: theme.palette.primary.main,
                      margin: "0 auto",
                      display: "block",
                    }}
                  />
                  <Typography
                    variant="h6"
                    component="h3"
                    align="center"
                    sx={{ mt: 2 }}
                  >
                    Relaxation Melodies
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    Soothing sounds to help you unwind and de-stress
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Music
                    size={48}
                    style={{
                      color: theme.palette.secondary.main,
                      margin: "0 auto",
                      display: "block",
                    }}
                  />
                  <Typography
                    variant="h6"
                    component="h3"
                    align="center"
                    sx={{ mt: 2 }}
                  >
                    Sleep Soundscapes
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    Gentle audio to guide you into a restful sleep
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Music
                    size={48}
                    style={{
                      color: theme.palette.primary.main,
                      margin: "0 auto",
                      display: "block",
                    }}
                  />
                  <Typography
                    variant="h6"
                    component="h3"
                    align="center"
                    sx={{ mt: 2 }}
                  >
                    Hypnobirthing Tracks
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    Specialized audio for a calm and confident birthing
                    experience
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <Box id="waitlist" sx={{ bgcolor: "primary.light", py: 8 }}>
          <Container maxWidth="md" sx={{ textAlign: "center" }}>
            <Typography variant="h3" component="h2" gutterBottom>
              Be the First to Know
            </Typography>
            <Typography variant="h6" color="text.secondary" paragraph>
              Goodnewzz is coming soon! Join our waitlist to get early access
              and exclusive updates.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<EmailIcon />}
              onClick={handleJoinWaitlist}
            >
              Join the Waitlist
            </Button>
          </Container>
        </Box>

        <Container maxWidth="md" sx={{ mt: 8, mb: 8, textAlign: "center" }}>
          <Typography variant="h3" component="h2" gutterBottom>
            Coming Soon to Your Favorite App Store
          </Typography>
          <Typography variant="h6" color="text.secondary" paragraph>
            Goodnewzz will be available for download soon. Join our waitlist to
            be notified when we launch!
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                size="large"
                startIcon={<AppleIcon />}
                sx={{
                  bgcolor: "black",
                  "&:hover": {
                    bgcolor: "#333",
                  },
                }}
                onClick={handleJoinWaitlist}
              >
                Coming to App Store
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                startIcon={<AndroidIcon />}
                sx={{
                  bgcolor: "#689f38",
                  "&:hover": {
                    bgcolor: "#558b2f",
                  },
                }}
                onClick={handleJoinWaitlist}
              >
                Coming to Google Play
              </Button>
            </Grid>
          </Grid>
        </Container>
      </main>

      <Container
        component="footer"
        maxWidth={false}
        sx={{ bgcolor: "primary.main", color: "primary.contrastText", py: 8 }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="div">
                Goodnewzz
              </Typography>
              <Typography variant="body2">
                Empowering expectant mothers with AI-powered insights and
                personalized care.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Quick Links
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="#">
                  Home
                </Button>
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="#features">
                  Features
                </Button>
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="#waitlist">
                  Join Waitlist
                </Button>
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="#">
                  Privacy Policy
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Email: support@goodnewzz.com
              </Typography>
              <Typography variant="body2">Phone: +1 (123) 456-7890</Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" align="center" sx={{ mt: 4 }}>
            © 2024 Goodnewzz. All rights reserved.
          </Typography>
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default LandingPage;
